import { Redirect2 } from 'compass-local/Link2'
import { useAppContext } from 'root/user'
import { Module } from 'modules/routes'

export default function Home() {
  const { payeeEnabled } = useAppContext()

  if (payeeEnabled) {
    return <Redirect2 href={Module('/projects').href} />
  } else {
    return <Redirect2 href={Module('/dashboard').href} />
  }
}
